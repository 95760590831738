import React from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineMail, AiFillMail, BiMailSend } from 'react-icons/all';
import style from './home.module.css';

export const WelcomeDesign = () => {
  return (
    <div className={'fixed bottom-0 right-0 h-full w-full max-w-[960px] z-0'}>
      <BiMailSend className={style['mail-logo']} />
      <AiOutlineMail className={[style['mail'], style['m1']].join(' ')} />
      <AiFillMail className={[style['mail'], style['m2']].join(' ')} />
      <AiOutlineMail className={[style['mail'], style['m3']].join(' ')} />
      <AiOutlineMail className={[style['mail'], style['m4']].join(' ')} />
      <AiFillMail className={[style['mail'], style['m5']].join(' ')} />
      <AiFillMail className={[style['mail'], style['m6']].join(' ')} />
    </div>
  );
};

export const Welcome = () => {
  const { t } = useTranslation();

  return (
    <section className={style['welcome']}>
      <h1 className={'text-8xl mt-14 md:ml-5'}>{t('mailbot')}</h1>
      <p className={'mt-4 md:ml-6 italic'}>{t('mailbot_catchphrase')}</p>
    </section>
  );
};

import React from 'react';
import {Welcome, WelcomeDesign} from './Welcome';
import style from './home.module.css';
import { Features } from './Features';
import {Prices} from "./Prices";

export const Home = () => {
  return (
    <main className={'flex justify-center items-center flex-col'}>
      <WelcomeDesign />
      <Welcome />
      <Features />
      <Prices />
      <div className={`h-screen ${style['join']}`}>JOIN</div>
    </main>
  );
};

import React from 'react';
import { Slide, Snackbar } from '@material-ui/core';
import Alert, { Color } from '@material-ui/lab/Alert';

export type Snack = {
  open: boolean;
  setOpen: () => void;
  message: string;
  type: Color;
};

type Props = Snack;

export const SnackbarWrapper = ({ type, message, open, setOpen }: Props) => {
  const handleClose = () => {
    setOpen();
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      autoHideDuration={2000}
      open={open}
      onClose={handleClose}
      TransitionComponent={Slide}
    >
      <Alert variant="filled" onClose={handleClose} severity={type}>
        {message}
      </Alert>
    </Snackbar>
  );
};

import React from 'react';
import style from './footer.module.css';

export const Footer = () => {
  return (
    <footer className={`${style['footer']} flex justify-center items-center w-full`}>
      <div className={'w-full text-center'}>
        Copyright © 2021 All Rights Reserved passion by Mailbot
      </div>
    </footer>
  );
};

import React, { createContext, useState } from 'react';
import { Color } from '@material-ui/lab/Alert';
import { SnackbarWrapper } from '../layout/Snackbar/SnackbarWrapper';

export const SnackbarContext = createContext((message: string, type: Color) => {
  //
});

export const SnackbarContainer = ({ children }: any) => {
  const [snack, setSnackInfo] = useState<{
    message: string;
    type: Color;
  }>({ message: '', type: 'success' });
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const setSnack = (message: string, type: Color) => {
    setSnackInfo({ message, type });
    setOpen(true);
  };

  return (
    <SnackbarContext.Provider value={setSnack}>
      <>{children}</>
      <SnackbarWrapper type={snack.type} message={snack.message} open={open} setOpen={handleClose} />
    </SnackbarContext.Provider>
  );
};

import React from 'react';
import style from './home.module.css';
import { Card } from '../../components/Card/Card';
import { useRest } from '@karpeleslab/react-klbfw-hooks';
import { Btn } from '../../layout/Btn/Btn';
import { useTranslation } from 'react-i18next';
import { Title } from '../../layout/Title/Title';

const FREE_PRODUCT_ID = 'cpr-wkpl7j-tshv-b7ra-vmbb-nlfcl7ly';
const STARTER_PRODUCT_ID = 'cpr-q4m4gz-jvin-cyvp-hkwr-aibbvc2q';
const PRO_PRODUCT_ID = 'cpr-lpisct-o3sj-hhfd-zayi-4hjdykz4';

export const Prices = () => {
  const { t } = useTranslation();

  const [products] = useRest<any>('Catalog/Product:search', {
    results_per_page: 20,
  });

  const getContent = (productId: string) => {
    if (!products) {
      return <></>;
    }
    const product = products.data.data.find((product: any) => product.Catalog_Product__ === productId);
    if (!product) {
      return <></>;
    }

    const price = FREE_PRODUCT_ID === productId ? t('free') : product.Price.display;

    return (
      <>
        <h3 className={'text-center text-2xl'}>{product['Basic.Name']}</h3>
        <div className={''}>{product['Description.Short']}</div>
        <div className={'flex justify-center items-center'}>
          <Btn type={'solid'}>{price}</Btn>
        </div>
      </>
    );
  };

  return (
    <section className={style['prices']}>
      <Title size={'text-4xl md:text-8xl'} position={'center'}>
        {t('packages')}
      </Title>
      {!products ? (
        <></>
      ) : (
        <>
          <div className={'grid grid-cols-1 md:grid-cols-3 gap-4 h-full'}>
            <Card className={'justify-between'}>{getContent(FREE_PRODUCT_ID)}</Card>
            <Card className={'justify-between'}>{getContent(STARTER_PRODUCT_ID)}</Card>
            <Card className={'justify-between'}>{getContent(PRO_PRODUCT_ID)}</Card>
          </div>
        </>
      )}
    </section>
  );
};

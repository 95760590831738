import React, { ReactNode } from 'react';
import style from './btn.module.css';
import { Link } from 'react-router-dom';

type Props = {
  children: ReactNode;
  className?: string;
  type?: 'solid' | 'simple' | 'clean' | 'link';
  to?: string;
  externalLink?: string;
  onClick?: () => void;
};

export const Btn = ({ children, type, className, to, externalLink, ...rest }: Props) => {
  const typeClass = type || 'simple';
  const classes = [style['mb-btn'], className, style[typeClass]].join(' ');
  if (to) {
    return (
      <Link to={to} className={classes} {...rest}>
        {children}
      </Link>
    );
  } else if (externalLink) {
    return (
      <a href={externalLink} className={classes} {...rest}>
        {children}
      </a>
    );
  }
  return (
    <button className={classes} {...rest}>
      {children}
    </button>
  );
};

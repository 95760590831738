import React from 'react';
import style from './home.module.css';
import { Title } from '../../layout/Title/Title';
import { useTranslation } from 'react-i18next';
import { AiFillApi, FaUserPlus, GrCloudComputer, GrServerCluster, MdDomain, MdUnsubscribe } from 'react-icons/all';
import { Card } from '../../components/Card/Card';

export const Features = () => {
  const { t } = useTranslation();

  return (
    <section className={style['features']}>
      <Title size={'text-4xl md:text-8xl'} position={'center'}>
        {t('features')}
      </Title>
      <div className={'grid grid-cols-2 md:grid-cols-4 gap-4'}>
        <Card>
          <>
            <AiFillApi className={''} size={'8rem'} />
            <h6 className={'font-extrabold text-sm mt-8'}>{t('features_api')}</h6>
          </>
        </Card>
        <Card>
          <>
            <FaUserPlus className={''} size={'8rem'} />
            <h6 className={'font-extrabold text-sm mt-8'}>{t('features_rep')}</h6>
          </>
        </Card>
        <Card>
          <>
            <MdUnsubscribe className={''} size={'8rem'} />
            <h6 className={'font-extrabold text-sm mt-8'}>{t('features_suppression')}</h6>
          </>
        </Card>
        <Card>
          <>
            <GrCloudComputer className={''} size={'8rem'} />
            <h6 className={'font-extrabold text-sm mt-8'}>{t('features_distributed')}</h6>
          </>
        </Card>
        <Card>
          <>
            <MdDomain className={''} size={'8rem'} />
            <h6 className={'font-extrabold text-sm mt-8'}>{t('features_domain')}</h6>
          </>
        </Card>
        <Card>
          <>
            <GrServerCluster className={''} size={'8rem'} />
            <h6 className={'font-extrabold text-sm mt-8'}>{t('features_mta')}</h6>
          </>
        </Card>
      </div>
    </section>
  );
};

import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Btn } from '../Btn/Btn';
import style from './nav.module.css';
import { FaMailBulk } from 'react-icons/all';
import { useMediaQuery } from '@material-ui/core';

export const Nav = () => {
  const { t } = useTranslation();
  const navRef = useRef<HTMLElement>(null);
  const isDesktop = useMediaQuery('(min-width: 768px)');

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }
    document.addEventListener('scroll', () => {
      if (navRef && navRef.current) {
        if (window.scrollY > 0) {
          navRef.current.classList.add('fixed');
          navRef.current.classList.add(style['sticky']);
          navRef.current.classList.remove('absolute');
        } else {
          navRef.current.classList.add('absolute');
          navRef.current.classList.remove('fixed');
          navRef.current.classList.remove(style['sticky']);
        }
      }
    });
  }, []);

  return (
    <header className={`${style['header']} w-full flex justify-center absolute top-0 left-0`} ref={navRef}>
      <nav className={'block flex justify-between items-center w-full'}>
        <div className={`${style['logo']} ml-2 md:ml-0 p-2 border-2 rounded-2xl cursor-pointer`}>
          <FaMailBulk />
        </div>
        {isDesktop ? (
          <div className={''}>
            <Btn type={'clean'} className={'mr-5'}>
              Test
            </Btn>
            <Btn type={'link'} className={'mr-5'}>
              Test 2
            </Btn>
            <Btn className={'mr-5'}>{t('sign_in')}</Btn>
            <Btn type={'solid'}>{t('sign_up_free')}</Btn>
          </div>
        ) : (
          <></>
        )}
      </nav>
    </header>
  );
};

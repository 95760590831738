import React, { ReactNode } from 'react';
import style from './card.module.css';

type CardProps = {
  children: ReactNode;
  className?: string;
};
export const Card = ({ children, className }: CardProps) => {
  return (
    <div className={`${style['card']} p-6 w-full flex flex-col justify-start items-center ${className}`}>
      {children}
    </div>
  );
};

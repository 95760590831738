import React, { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  className?: string;
  position: 'left' | 'right' | 'center';
  size?: string;
};

export const Title = ({ children, className, position, size }: Props) => {
  if (!size) {
    size = 'text-4xl';
  }

  let positionClasses;
  if (position === 'left') {
    positionClasses = 'pl-4 text-left';
  } else if (position === 'right') {
    positionClasses = 'text-right pr-4';
  } else {
    positionClasses = 'text-center';
  }
  return <h1 className={[size, 'font-bold mb-10', positionClasses, className].join(' ')}>{children}</h1>;
};

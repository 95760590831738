import React from 'react';
import { RestContextContainer } from '@karpeleslab/klb-react-services';
import { SnackbarContainer } from './contexts/SnackbarContext';
import { PageLoaderContainer } from './contexts/PageLoaderContext';
import { Route, Switch } from 'react-router-dom';
import { Home } from './pages/Home/Home';
import { Nav } from './layout/Nav/Nav';
import { Footer } from './layout/Footer/Footer';
import style from './app.module.css';

const Page = (): JSX.Element => {
  return (
    <>
      <Nav />

      <div className={style['page']}>
        <Switch>
          <Route path="*">
            <Home />
          </Route>
        </Switch>
      </div>
      <Footer />
    </>
  );
};

export const App = () => {
  return (
    <RestContextContainer>
      <SnackbarContainer>
        <PageLoaderContainer>
          <Page />
        </PageLoaderContainer>
      </SnackbarContainer>
    </RestContextContainer>
  );
};
